import React from "react";
import { graphql } from "gatsby";
import PageHeader from "../components/_global/layout/PageHeader";
import Section from "../components/_global/layout/Section";
import Layout from "../components/_global/layout/Layout";
import localize from "../components/_global/localization/localize";
import Spacer from "../components/_global/layout/Spacer";
import DonateComponent from "../components/1_home/DonateComponent";
import VolunteerSection from "../components/_global/VolunteerSection";
import NewsletterSection from "../components/_global/NewsletterSection";
import styled from "styled-components";
import LinkBlock from "../components/7_babysitters/LinkBlock";
// import SelectedProjects from "../components/9_get-involved/SelectedProjects";

const LinkBlocksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    flex-direction: column;
  }
`;

const HelpUs = ({ data, ...props }) => {
	const {
		page, donate, volunteer, newsletter, babysitters
		// general
	} = data;

	return (
		<Layout title={page.common.title}>
			<PageHeader title={page.common.title} image={page.splashImage} />

			<Section m="small">
				<VolunteerSection data={volunteer} />
				<Spacer s="large" />

				<LinkBlocksContainer m="small">
					<LinkBlock text={babysitters.babysitterText} button={babysitters.babysitterButton} />
					<Spacer s="medium" />
					<LinkBlock text={babysitters.parentText} button={babysitters.parentButton} />
				</LinkBlocksContainer>
				<Spacer s="large" />

				{/*<SelectedProjects*/}
				{/*	title={page.projectsTitle}*/}
				{/*	data={page.projects}*/}
				{/*	learnMore={general.learnMore}*/}
				{/*/>*/}
				{/*<Spacer s="large" />*/}

				<NewsletterSection data={newsletter} />
				<Spacer s="large" />

				<DonateComponent data={donate} />
				<Spacer s="large" />
			</Section>
		</Layout>
	);
};

export default localize(HelpUs);

export const query = graphql`
    {
        page: sanityGetInvolved {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            splashImage
            {
                ...
                ImageFragment
            }
            projectsTitle {
                _type
                fr
            }
            projects {
                title {
                    _type
                    fr
                }
                slug {
                    current
                }
                description {
                    _type
                    fr
                }
                time
                image {
                    ...ImageFragment
                }
                content {
                    ...CompleteBlockFragment
                }
            }
        }
        general: sanityGeneralComponent {
            learnMore {
                _type
                fr
            }
        }
        babysitters: sanityBabysitters {
            babysitterText {
                ...CompleteBlockFragment
            }
            babysitterButton {
                ...LinkFragment
            }
            parentText {
                ...CompleteBlockFragment
            }
            parentButton {
                ...LinkFragment
            }
        }
        donate: sanityDonateComponent {
            ...DonateComponentFragment
        }
        volunteer: sanityVolunteerComponent {
            ...VolunteerComponentFragment
        }
        newsletter: sanityNewsletterComponent {
            button {
                ...LinkFragment
                #                title {
                #                    _type
                #                    fr
                #                }
                #                linkType
                #                _rawInternalLink(resolveReferences: {maxDepth: 1})
            }
            text {
                _type
                fr
            }
            title {
                _type
                fr
            }
        }
    }
`;
